<!-- Control Multi-Finder Fechas -->

<template>
    <!-- {{`date1: ${dateF[1]}`}} -->
  <div class="uiMultiFilterFechas" style="display:flex">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">

        <v-dialog v-model="modal" width="390px">
          <template v-slot:activator="{}">
            <!-- Si tipo es 'fecha', sólo mostramos un textfield con la fecha -->
            <template v-if="tipo === 'fecha'">
              <v-text-field
                v-bind="$input"
                v-model="dateF[0]"
                :label="elem_menuSeleccionado.label"
                :disabled="disabled"
                :append-icon="Entorno.icono_calendario"
                @focus="onFocus(0)"
                @blur="onBlur"
                @keypress.13="searchFn('buscarMultifilterFechas')"
                @dblclick="openModal(0)"
                @click:append="openModal(0)"
                @keydown="check_key"
                @keyup="fillDate"
                @input="inputTextfield($event)">
                  <template v-slot:prepend-inner v-if="items.length > 1">
                    <v-icon v-on="on">{{ "mdi-menu-down" }}</v-icon>
                  </template>
              </v-text-field>
            </template>

            <!-- Si tipo es 'periodo', mostramos dos textfields con 2 fechas -->
            <template v-else-if="tipo === 'periodo'">
              <v-text-field
                v-bind="$input"
                v-model="dateF[1]"
                :label="'Desde ' + `${elem_menuSeleccionado.label}`"                
                :disabled="disabled"                
                @focus="onFocus(1)"
                @blur="onBlur"
                @dblclick="openModal(1)"                
                @keydown="check_key"
                @keyup="fillDate"
                @input="inputTextfield($event)">                              
                  <template v-slot:prepend-inner v-if="items.length > 1">
                    <v-icon v-on="on">{{ Entorno.icono_menu }}</v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon v-if="dateF[1]" @click="limpia(1)">{{ 'mdi mdi-close' }}</v-icon>
                    <v-icon @click="openModal(1)">{{ 'mdi mdi-calendar' }}</v-icon>
                  </template>
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="dateF[2]"
                :label="'Hasta ' + `${elem_menuSeleccionado.label}`"                
                :disabled="disabled"
                @focus="onFocus(2)"
                @blur="onBlur()"
                @dblclick="openModal(2)"                
                @keydown="check_key"
                @keyup="fillDate"
                @input="inputTextfield($event)"
                @keypress.13="searchFn('buscarMultifilterFechas')">              
                  <template v-slot:append>
                    <v-icon v-if="dateF[2]" @click="limpia(2)">{{ 'mdi mdi-close' }}</v-icon>
                    <v-icon @click="openModal(2)">{{ 'mdi mdi-calendar' }}</v-icon>
                  </template>
              </v-text-field>
            </template>
          </template>

          <!-- DatePicker -->
          <v-date-picker
            v-bind="DpEntorno"
            v-model="date[idx]"
            locale="es-Es"
            :allowed-dates="allowed_dates"
            :min="min"
            :max="max"
            reactive
            @input="modal = false"
            @change="validate_periodo">          
          </v-date-picker>
        </v-dialog>      
      </template>

      <!-- Lista de opciones de Busqueda -->
      <v-list>
        <div style="text-align:center"> Seleccione filtro </div>
        <v-divider></v-divider>
        
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title @click="onclick_selectBusqueda(item)">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: [Object, Array], required: true },
    ct: { type: [Object, Array], required: true },
    ctrl: { type: [Object, Array], default: () => {}},
    tipo: { type: String, default: "fecha" },
    disabled: { type: Boolean, default: false },
    dates: { type: Array, default: () => [] },
    min: { type: String, default: "" },
    max: { type: String, default: "" },
    searchFn: { type:Function, default: null },
  },

  data() {
    return {
      // variables de configuración
      Entorno: this.$cfg.ctrls.multifilterFechas,
      DpEntorno: this.$cfg.datePicker,
      sep: this.$store.state.separador_fecha,

      // variables del componente
      elem_menuSeleccionado: null,
      modal: false,
      idx: null,
      date: { 0: "", 1: "", 2: "" },
      save_date: { 0: "", 1: "", 2: "" },
      dateF: { 0: "", 1: "", 2: "" },
      save_dateF: { 0: "", 1: "", 2: "" }
    };
  },

  // establezco el ctrl de busqueda al primer elemento del array
  // de ctrls recibido
  created() {
    if (!this.items) return;
    this.setBusqueda(this.items[0]);
  },

  methods: {
    // actualizo el nombre del control en el que estoy
    onFocus(idx) {
      this.idx = idx;
    },

    // limpio datos de todo los items
    limpia(index) {   
      this.date[index] = '';
      this.dateF[index] = '';

      //this.$set(this.ctrl, 2, '');
    },

    // al salir del ctrl valido la fecha y la pongo en formato visual
    onBlur() {      
      var date = this.validate_date();
      this.date[this.idx] = date;
      this.dateF[this.idx] = this.formatDate(date);

      if (this.tipo== 'periodo') this.validate_periodo();      
    },

    // actualizo el value del texto introducido en el textfield
    inputTextfield(inputValue) {        
      this.dateF[this.idx] = inputValue;
    },

    // selecciono un tipo de busqueda de la lista asociada al textfield
    // , inicializo a '' sus valores
    onclick_selectBusqueda(item) {      
      //this.limpia();
      this.setBusqueda(item);      
    },

    // emito evento buscar para proceder a la busqueda
    onclick_buscar() {
      if (!this.elem_menuSeleccionado) return;
      this.$emit("onSearch", this.elem_menuSeleccionado.buscar);
    },

    // configuro la busqueda con el item recibido.
    // actualizo el label del textfield y guardo el item seleccionado
    setBusqueda(item) {
      this.elem_menuSeleccionado = item;
      this.$set(this.ct[this.ctrl[4].comp.f2], 2, item.ctrl);
      this.$emit('onEvent', { accion:'change', item:item })
    },

    // formateo fecha de sql a visual
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day.toString().padStart(2, "0")}${
        this.sep
      }${month.toString().padStart(2, "0")}${this.sep}${year}`;
    },

    // formateo fecha de visual a sql
    parseDate(date) {
      if (!date) return "";

      const [day, month, year] = date.split(this.sep);
      return `${year}-${month}-${day}`;
    },

    // muestro u oculto datepicker y guardo el control seleccionado
    openModal(idx) {      
      this.onFocus(idx);
      this.modal = !this.modal;
    },

    // relleno con separadores al introducir la fecha manualmente
    fillDate(e) {
      var key = e.keyCode;
  
      if (key === 32 || key === 8) return;
      if (
        this.dateF[this.idx].length === 2 ||
        this.dateF[this.idx].length === 5
      )
        this.dateF[this.idx] += this.sep;
    },

    // solo permito introducir números manualmente
    check_key(e) {
      var key = e.keyCode;

      if (key === 8 || key === 9) return;
      if (key < 48 || (key > 57 && (key<96 || key>105)) || key === 32) {
        e.preventDefault();
        return;
      }
      if (this.dateF[this.idx].length >= 10) {
        e.preventDefault();
        return;
      }
    },

    // valido fecha
    validate_date() {
      let date= this.dateF[this.idx];
      if (date === "") return date;

      const fecha = new Date();
      const sep = this.sep;
      const d = date.split(sep);
      const day = d[0].length === 1 ? "0" + d[0] : d[0];
      const month =
        d[1] !== undefined && d[1] !== ""
          ? d[1].length === 1
            ? d[1].padStart(2, "0")
            : d[1]
          : (fecha.getMonth() + 1).toString().padStart(2, "0");
      const year =
        d[2] !== undefined && d[2] !== ""
          ? d[2].length < 4
            ? fecha.getFullYear()
            : d[2]
          : fecha.getFullYear();

      if (isNaN(new Date(`${year}${"-"}${month}${"-"}${day}`)))
        return `${fecha.getFullYear()}${"-"}${fecha.getMonth() +
          1}${"-"}${fecha.getDate()}`;
      return `${year}${"-"}${month}${"-"}${day}`;
    },

    // compruebo si la fecha recibida está dentro de las fechas permitidas
    allowed_dates: function(val) {
      if (this.dates.length === 0) return true;
      return this.dates.indexOf(val) !== -1;
    },


    // compruebo si el rango de fechas del período es correcto
    validate_periodo() {
        if (this.date[1]== "" || this.date[2]== "") return;

        if (this.idx== 1 && (this.date[2]!= "" && this.date[2]< this.date[1])) return this.error_periodo();
        if (this.idx== 2 && (this.date[1]!= "" && this.date[1]> this.date[2])) return this.error_periodo();
    },


    // entro si las fechas del periodo no son correctas, es decir,
    // si la fecha del hasta es superior a la del desde, etc...
    // Limpio los datos de la fecha errónea y muestro error
    error_periodo() {
      this.date[2]= this.dateF[2]= "";
      this.$root.$alert.open('Período de fechas erróneo', 'error');      
    }
  },


  watch: {
    date: {
      deep: true,

      handler: function(val) {
        if (this.tipo== 'periodo' && (this.date[1]== "" && this.date[2]== "")) {
          this.$set(this.ctrl, 2, '');
          return;
        }
        this.dateF[this.idx] = this.formatDate(val[this.idx]);
        
        // si la fecha es un periodo guardo las dos fechas del periodo
        // en el value del schema, si es una fecha única, guardamos solo esa fecha
        if (this.tipo === "fecha") {
          //this.ct[this.elem_menuSeleccionado.ctrl][2] = this.parseDate(this.dateF[this.idx]);
          this.$set(this.ctrl, 2, this.parseDate(this.dateF[this.idx]));

        } else {                    
          this.$set(this.ctrl, 2, this.parseDate(this.dateF[1]) + "|" + this.parseDate(this.dateF[2]));
          //this.ct[this.elem_menuSeleccionado.ctrl][2]= this.parseDate(this.dateF[1]) + "|" + this.parseDate(this.dateF[2]);
        }
      }
    }
  }
};
</script>

<style scope>
.multi_filterFechas .boton_busqueda {
  margin: 4px 0px 0px 10px;
}
</style>
